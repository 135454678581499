<template>
  <v-container tag="section">
    <validation-observer ref="observer" slim v-slot="{ invalid }">
      <v-row align="center">
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-microsoft-excel"
            :title="title"
            inline
            class="px-5 py-4 ml-0"
          >
            <v-form class="mt-5">
              <v-row align="center" class="mx-auto">
                <v-col 
                  cols="12" 
                  v-if="relatorio.descricao"
                >
                  <v-alert
                    dense 
                    outlined 
                    type="info" 
                    color="blue" 
                    class="mb-0 py-1 px-1"
                  >
                    {{ relatorio.descricao }}
                  </v-alert>
                </v-col>  
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" rules="required|max:100" id="titulo-relatorio">
                    <v-text-field
                      label="Título"
                      outlined
                      :error-messages="errors"
                      v-model="novoRelatorio.titulo"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <base-material-card
            color="primary"
            icon="mdi-filter"
            title="Filtros e parâmetros"
            inline
            class="px-5 py-4 ml-0"
          >
            <v-form class="mt-5">
              <component 
                id="mais-filtros"
                v-bind:is="formFiltros"
                :item="{'filtros': novoRelatorio.filtros}"
                @filtersUpdated="atualizaFiltros"
              />
            </v-form>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <v-btn
            id="btn-save-relatorio"
            color="primary"
            min-width="100"
            style="float: right;"
            :disabled="invalid"
            :loading="loading"
            @click.stop="openConfirmationDialog()"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-dialog
      v-model="confirmationDialog"
      max-width="720"
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirmar Importação de Dados
        </v-card-title>
        <v-card-text>
          <v-alert
            dense 
            outlined 
            type="warning" 
            class="mb-1 py-1 px-1"
          >
            <strong>ATENÇÃO</strong>
            <br>Você selecionou a opção <b>Importação de Indicadores para Validação</b>, esteja ciente de que quaisquer dados existentes para as Competências selecionadas serão substituídos pelos dados do EQTLINFO.
            <br>Isso também irá executar novamente as validações dos indicadores, qualquer trabalho existente de aprovação de indicadores será perdido.
            <br><br><b>Siga por sua conta e risco.</b>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="confirmationDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="salvarRelatorio()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import RelatoriosService from '@/services/RelatoriosService';
import RelatoriosCriadosService from '@/services/RelatoriosCriadosService';
import tourMixins from '@/mixins/tourMixins';
import components from '@/components/relatorios/forms/components.js';

export default {
  components,
  mixins: [
    tourMixins
  ],
  data() {
    return {
      slug: null,
      relatorio: {
        nome: null
      },
      loading: false,
      novoRelatorio: {
        titulo: null,
        filtros: {},
        relatorio_id: null,  
      },
      arrTour: [
        {
          element: '#titulo-relatorio',
          intro: 'Informe um nome para o novo relatório.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
        {
          element: '#mais-filtros',
          intro: 'Informe aqui os filtros e parâmetros específicos do tipo de relatório selecionado.',
          scrollTo: 'tooltip',
          position: 'bottom'
        },
      ],
      tourFuncCalled: false,
      confirmationDialog: false,
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.getRelatorio();
  },
  computed: {
    title() {
      return `Adicionar relatório :: ${this.relatorio.nome}`;  
    },
    formFiltros() {
      return this.slug;  
    }
  },
  watch: {
    relatorio() {
      this.novoRelatorio.titulo = this.relatorio.nome;
      this.novoRelatorio.relatorio_id = this.relatorio.id;  
    }  
  },
  methods: {

    getRelatorio() {
      RelatoriosService
        .getRelatorioBySlug(this.slug)
        .then(relatorio => {
          this.relatorio = relatorio;

          /* Caso exista o parametro id, significa que o usuário está tentando editar um relatório existente */
          if (this.$route.params.id) {
            this.changeBreadcrumb(relatorio.nome, 'Editar');
            this.getRelatorioCriadoById(this.$route.params.id);
          } else {
            this.changeBreadcrumb(relatorio.nome, 'Adicionar');
            this.iniciarTourPagina();
          }
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar o dados do relatório.', '', { position: 'topRight' });
        });
    },
    changeBreadcrumb(nomeRelatorio, tipoRelatorio){
      this.$route.meta.breadcrumb = [
        { text: 'Relatórios', to: '/relatorios', exact: true},
        { text: nomeRelatorio, to: `/relatorios/${this.$route.params.slug}/historico`, exact: true },
        { text: tipoRelatorio, exact: false }
      ]; 
      //atualiza variavel no store, com isso o watch do appBar atualizar breadcrumb;
      this.$store.commit('WATCH_CHANGE_BREADCRUMB');
    },

    getRelatorioCriadoById(id) {
      RelatoriosCriadosService
        .getRelatorioCriadoById(id)
        .then(relatorio => {
          this.$set(this.novoRelatorio, 'titulo', relatorio.titulo);
          this.$set(this.novoRelatorio, 'filtros', relatorio.filtros);
          this.iniciarTourPagina();
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar o dados do relatório para edição.', '', { position: 'topRight' });
        });
    },

    openConfirmationDialog() {
      if (this.novoRelatorio.filtros?.importar_indicadores_para_validacao) {
        this.confirmationDialog = true;
      } else {
        this.salvarRelatorio();
      }
    },

    async salvarRelatorio() {
      
      this.confirmationDialog = false;
      this.loading = true;
      
      try {

        const id = this.$route.params.id ? this.$route.params.id : 0;

        await RelatoriosCriadosService.salvar(id, this.novoRelatorio);
        this.$toast.success('Relatório cadastrado com sucesso.', '', { position: 'topRight' });
        this.$router.push({
          name: 'Relatórios Histórico'
        })

      } catch (e) {

        this.$toast.error('Ocorreu um erro ao cadastrar o novo relatório.', '', { position: 'topRight' });
        console.log(e);

      } finally {
        this.loading = false;
      }
    },

    atualizaFiltros(filtros) {
      this.novoRelatorio.filtros = filtros;
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;
      this.tourFuncCalled = true;
      this.iniciarTour(this.arrTour);
    }
  } 
}

</script>